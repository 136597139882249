import { RouteComponentProps } from "@reach/router";
import SizedBox from "../../components/SizedBox";
import FormGroup from "react-bootstrap/FormGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import FormLabel from "react-bootstrap/FormLabel";
import FormControl from "react-bootstrap/FormControl";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { ForgotPasswordMutation } from "../../utils/graphql/mutations";
import Alert from "react-bootstrap/Alert";
import { Helmet } from "react-helmet";

const ForgotPassword = (_: RouteComponentProps) => {
  const [sendEmail, { loading, error, called }] = useMutation(
    ForgotPasswordMutation,
    {
      onError: (error: any) => {
        console.log(error);
      },
    }
  );
  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values: any) => {
      sendEmail({
        variables: {
          email: values.email,
        },
      });
    },
  });

  return (
    <div>
      <Helmet>
        <title>Forgot password</title>
      </Helmet>
      <h1>Forgot Password</h1>
      <p>
        Please enter your email address below and we'll send you an email to
        help you reset it.
      </p>
      <SizedBox height={50} />
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <FormLabel>Email address</FormLabel>
          <FormControl
            value={values.email}
            onChange={handleChange}
            name="email"
            type="email"
          />
        </FormGroup>
        <ButtonToolbar>
          {loading ? (
            <p>Please wait... </p>
          ) : (
            <Button type="submit">Send email</Button>
          )}
        </ButtonToolbar>
        <SizedBox height={10} />
        {called && !loading ? (
          <Alert variant={error && called ? "danger" : "success"}>
            {error && called
              ? "Unable to send reset email"
              : "Please check your email to continue"}
          </Alert>
        ) : null}
      </form>
    </div>
  );
};

export default ForgotPassword;
