import { RouteComponentProps } from "@reach/router";
import Container from "react-bootstrap/Container";
import NavigationBar from "../../components/NavigationBar";

interface AppIndexRouteProps extends RouteComponentProps {
  children?: any;
}

const AppIndex = (props: AppIndexRouteProps) => {
  return (
    <Container fluid style={{ padding: 0 }}>
      <NavigationBar />
      <div>{props.children}</div>
    </Container>
  );
};

export default AppIndex;
