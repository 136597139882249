import { RouteComponentProps } from "@reach/router";
import { Redirect } from "@reach/router";

interface ProtectedRouteProps extends RouteComponentProps {
  Component?: any;
  children?: any;
}

const ProtectedRoute = ({
  Component,
  children,
  ...rest
}: ProtectedRouteProps) => {
  let token = localStorage.getItem(`${process.env.REACT_APP_TOKEN_KEY}`);
  if (token === null) {
    return <Redirect to="/auth" noThrow />;
  } else {
    return <Component {...rest}>{children}</Component>;
  }
};

export default ProtectedRoute;
