import { RouteComponentProps } from "@reach/router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../styles/auth/authindex.css";
import { useEffect } from "react";

interface AuthIndexRouteProps extends RouteComponentProps {
  children?: any;
}
const AuthIndex = (props: AuthIndexRouteProps) => {
  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <Container fluid>
      <Row>
        <Col className="pictureBackground"></Col>
        <Col
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Container
            style={{
              width: "500px",
            }}
          >
            {props.children}
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default AuthIndex;
