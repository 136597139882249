import SizedBox from "../../components/SizedBox";
import FormGroup from "react-bootstrap/FormGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import FormLabel from "react-bootstrap/FormLabel";
import FormControl from "react-bootstrap/FormControl";
import { navigate, RouteComponentProps, useLocation } from "@reach/router";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { ResetPasswordMutation } from "../../utils/graphql/mutations";
import { parse } from "query-string";

const emailValidator = yup.object().shape({
  password: yup.string().required("Enter a password"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ResetPassword = (_: RouteComponentProps) => {
  const queryLocation = useLocation();

  const [resetPassword, { loading }] = useMutation(ResetPasswordMutation, {
    onError: (error: any) => {
      alert("Unable to reset password");
      console.log(error);
    },
    onCompleted: (data: any) => {
      localStorage.setItem(
        `${process.env.REACT_APP_TOKEN_KEY}`,
        data.resetPassword.jwt
      );
      navigate("/", { replace: true });
    },
  });
  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: emailValidator,
    onSubmit: (values: any) => {
      const queryParams = parse(queryLocation.search);
      resetPassword({
        variables: {
          password: values.password,
          passwordConfirmation: values.confirmPassword,
          code: queryParams.code,
        },
      });
    },
  });
  return (
    <div>
      <Helmet>
        <title>Reset password</title>
      </Helmet>
      <h1>Reset your password</h1>
      <p>One last step. Create your new password below.</p>
      <SizedBox height={50} />
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <FormLabel>New password</FormLabel>
          <FormControl
            onChange={handleChange}
            name="password"
            type="password"
            value={values.password}
          />
          {errors.password && touched.password ? (
            <span style={{ color: "red" }}>{errors.password}</span>
          ) : null}
        </FormGroup>
        <FormGroup>
          <FormLabel>Confirm the password</FormLabel>
          <FormControl
            onChange={handleChange}
            name="confirmPassword"
            type="password"
            value={values.confirmPassword}
          />
          {errors.confirmPassword && touched.confirmPassword ? (
            <span style={{ color: "red" }}>{errors.confirmPassword}</span>
          ) : null}
        </FormGroup>
        <ButtonToolbar>
          {loading ? <p>Please wait</p> : <Button type="submit">Submit</Button>}
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default ResetPassword;
