import Container from "react-bootstrap/Container";
import Helmet from "react-helmet";
import Button from "react-bootstrap/Button";
import { RouteComponentProps } from "@reach/router";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SizedBox from "../../components/SizedBox";
import ListGroup from "react-bootstrap/ListGroup";
import { useMutation, useQuery } from "@apollo/client";
import { MeQuery } from "../../utils/graphql/queries";
import {
  UpdateUserMutation,
  ForgotPasswordMutation,
} from "../../utils/graphql/mutations";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState } from "react";

const profileFormValidator = yup.object().shape({
  email: yup
    .string()
    .email("A valid email address is required")
    .required("An email is required"),
});

const Profile = (_: RouteComponentProps) => {
  const [userId, setUserId] = useState(null);

  const [sendResetEmail] = useMutation(ForgotPasswordMutation, {
    onCompleted: () => {
      alert("Password reset email sent");
    },
  });
  const [updateUser, { loading: updateLoading }] = useMutation(
    UpdateUserMutation,
    {
      onError: (_: any) => {
        console.log(_);
        alert("Unable to update data");
      },
      onCompleted: () => {
        alert("Saved!");
      },
    }
  );
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: profileFormValidator,
    onSubmit: (values: any) => {
      updateUser({
        variables: {
          input: {
            where: { id: userId },
            data: {
              username: values.email,
              email: values.email,
            },
          },
        },
      });
    },
  });

  const { loading, error, data: userData } = useQuery(MeQuery, {
    onCompleted: (data: any) => {
      setUserId(data.myInfo.id);
      setFieldValue("email", data.myInfo.email);
    },
  });
  if (loading) return <p>Please wait</p>;
  if (error) return <p>Unable to load data</p>;

  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <Container style={{ paddingTop: "4rem", paddingBottom: "4rem" }}>
        <h1>Hi, {userData.myInfo.name}</h1>
        <p>
          Here's where you can change a few things about your account. For now,
          only your email can change
        </p>
      </Container>
      <Container>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  onChange={handleChange}
                  name="email"
                  value={values.email}
                />
                {errors.email && touched.email ? (
                  <Form.Text style={{ color: "red" }}>{errors.email}</Form.Text>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <SizedBox height={20} />
          <ButtonToolbar>
            <Button disabled={updateLoading} type="submit" variant="primary">
              Save
            </Button>
            <SizedBox width={10} />
            <Button
              onClick={() => sendResetEmail()}
              type="button"
              variant="light"
            >
              Reset Password
            </Button>
          </ButtonToolbar>
        </Form>
        <SizedBox height={20} />
        <hr />
        <SizedBox height={20} />
        <h3>Commision Information</h3>
        <p>
          Your reports will use the criteria below to determine your commission
          on each sale associated with your discount code
        </p>
        <SizedBox height={10} />
        <ListGroup>
          <ListGroup.Item>
            Commission Type:{" "}
            {userData.myInfo.commissionType?.value ?? "No commission setup"}
          </ListGroup.Item>
          <ListGroup.Item>
            Commission Value:{" "}
            {userData.myInfo.commissionValue ?? "No commission setup"}
          </ListGroup.Item>
        </ListGroup>
      </Container>
    </>
  );
};

export default Profile;
