import { navigate, RouteComponentProps } from "@reach/router";
import FormControl from "react-bootstrap/FormControl";
import FormLabel from "react-bootstrap/FormLabel";
import FormGroup from "react-bootstrap/FormGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import SizedBox from "../../components/SizedBox";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { LoginMutation } from "../../utils/graphql/mutations";

const Login = (_: RouteComponentProps) => {
  const [loginUser, { loading }] = useMutation(LoginMutation, {
    onCompleted: (data: any) => {
      localStorage.setItem(
        `${process.env.REACT_APP_TOKEN_KEY}`,
        data.login.jwt
      );
      navigate("/", { replace: true });
    },
    onError: (error: any) => {
      alert("Incorrect username/password");
    },
  });
  const { values, handleSubmit, handleChange } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values: any) => {
      loginUser({
        variables: {
          input: {
            identifier: values.username,
            password: values.password,
          },
        },
      });
    },
  });

  return (
    <div>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <h1>Login to continue</h1>
      <SizedBox height={50} />
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <FormLabel>Username</FormLabel>
          <FormControl
            value={values.username}
            name="username"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Password</FormLabel>
          <FormControl
            value={values.password}
            name="password"
            onChange={handleChange}
            type="password"
          />
        </FormGroup>
        <ButtonToolbar>
          {loading ? (
            <p>Please wait...</p>
          ) : (
            <Button type="submit">Login</Button>
          )}
          <SizedBox width={10} />
          <Button
            onClick={() => navigate("/auth/forgot-password")}
            variant="link"
          >
            Forgot password?
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default Login;
