import { useQuery } from "@apollo/client";
import { RouteComponentProps } from "@reach/router";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import { Helmet } from "react-helmet";
import SizedBox from "../../components/SizedBox";
import "../../styles/app/code.css";
import { GetPaymentsByCode } from "../../utils/graphql/queries";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import moment from "moment";

interface CodeProps extends RouteComponentProps {
  id?: string | number;
}
const Code = ({ id }: CodeProps) => {
  const { loading, error, data } = useQuery(GetPaymentsByCode, {
    variables: { code: id },
  });
  if (loading)
    return (
      <div>
        <Container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "4rem",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Container>
      </div>
    );

  if (error)
    return (
      <Container>
        <SizedBox height={20} />
        <p>Unable to load data</p>
      </Container>
    );

  return (
    <>
      <Helmet>
        <title>View Code</title>
      </Helmet>
      <Container className="hero-banner" fluid>
        <Container style={{ paddingTop: "4rem", paddingBottom: "4rem" }}>
          <h1>
            Viewing for <em>"{id}"</em>
          </h1>
        </Container>
      </Container>
      <SizedBox height={20} />
      <Container>
        <Form
          style={{
            border: "1px solid #ced4da",
            padding: "1rem",
            borderRadius: ".25rem",
          }}
        >
          <Container>
            <Row>
              <Col sm={12} lg={4}>
                <FormGroup>
                  <Row>
                    <Col sm={12} lg={6}>
                      <FormLabel>Date from:</FormLabel>
                      <FormControl type="date" />
                    </Col>
                    <Col sm={12} lg={6}>
                      <FormLabel>Date to:</FormLabel>
                      <FormControl type="date" />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col sm={12} lg={4}>
                <FormGroup>
                  <FormLabel>Status Search</FormLabel>
                  <FormControl
                    as="select"
                    placeholder="card_payment or mobile_money"
                  >
                    <option value="">All</option>
                    <option value="new">Incomplete</option>
                    <option value="honored">Complete</option>
                  </FormControl>
                </FormGroup>
              </Col>
              <Col sm={12} lg={4}>
                <FormGroup>
                  <FormLabel>Payment Method</FormLabel>
                  <FormControl
                    as="select"
                    placeholder="card_payment or mobile_money"
                  >
                    <option value="">All</option>
                    <option value="card_payment">
                      Card (Visa/Mastercard etc)
                    </option>
                    <option value="mobile_money">Mobile Money</option>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
            <ButtonGroup className="mr-2">
              <Button type="submit">Search</Button>
              <SizedBox width={5} />
              <Button variant="secondary" type="button">
                Clear
              </Button>
            </ButtonGroup>
          </Container>
        </Form>
        <SizedBox height={30} />
        <Table striped bordered>
          <thead>
            <tr>
              <th>Token</th>
              <th>Price</th>
              <th>Commision</th>
              <th>Status</th>
              <th>Source</th>
              <th>Date Honored</th>
              <th>Created</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {data.fetchByCode.map((payment: any) => {
              return (
                <tr>
                  <td>
                    <Button variant="link" onClick={() => alert(payment.token)}>
                      Click to view
                    </Button>
                  </td>
                  <td>{payment.price}</td>
                  <td>{payment.commission}</td>
                  <td>
                    <Badge
                      variant={payment.status === "new" ? "warn" : "success"}
                    >
                      {payment.status === "new" ? "Incomplete" : "Compelete"}
                    </Badge>
                  </td>
                  <td>{payment.source}</td>
                  <td>{moment(payment.date_honoured).format("LL")}</td>
                  <td>{payment.description}</td>
                  <td>{moment(payment.created_at).format("LL")}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
    </>
  );
};
export default Code;
