import { Router } from "@reach/router";
import AppIndex from "./routes/app/AppIndex";
import AuthIndex from "./routes/auth/AuthIndex";
import Helmet from "react-helmet";
import Login from "./routes/auth/Login";
import ForgotPassword from "./routes/auth/ForgotPassword";
import ResetPassword from "./routes/auth/ResetPassword";
import Home from "./routes/app/Home";
import AllSales from "./routes/app/AllSales";
import Profile from "./routes/app/Profile";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import ProtectedRoute from "./components/ProtectedRoute";
import { setContext } from "@apollo/client/link/context";
import { createHttpLink } from "@apollo/client/link/http";
import Code from "./routes/app/Code";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(`ss_affiliates`);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_URL,
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const App = () => {
  return (
    <>
      <ApolloProvider client={client}>
        <Helmet titleTemplate="%s | Smart Saver Affiliates"></Helmet>
        <Router>
          <ProtectedRoute Component={AppIndex} default path="/">
            <Home path="/" />
            <AllSales path="/all-sales" />
            <Profile path="/profile" />
            <Code path="/code/:id" />
          </ProtectedRoute>
          <AuthIndex path="/auth">
            <Login path="/" />
            <ForgotPassword path="/forgot-password" />
            <ResetPassword path="/reset-password" />
          </AuthIndex>
        </Router>
      </ApolloProvider>
    </>
  );
};

export default App;
