import { gql } from "@apollo/client";

export const MeQuery = gql`
  query MeQuery {
    myInfo {
      id
      name
      email
      commissionType {
        value
      }
      commissionValue
    }
  }
`;

export const HomeQuery = gql`
  query HomeQuery {
    completeSalesToday
    incompleteSalesToday
    allCompleteSales
    allIncompleteSales
    myInfo {
      id
      name
    }
    myCoupons {
      id
      code
      active
      name
    }
  }
`;

export const GetPaymentsByCode = gql`
  query GetPaymentsByCode($code: String!) {
    fetchByCode(code: $code) {
      id
      token
      price
      status
      discount_code {
        id
        name
        code
      }
      source
      date_honoured
      description
      created_at
      commission
    }
  }
`;

export const GetAffiliateSales = gql`
  query GetAffiliateSales {
    affiliatePayments {
      id
      token
      price
      status
      discount_code {
        id
        name
        code
      }
      source
      date_honoured
      description
      created_at
      commission
    }
  }
`;
