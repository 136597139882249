import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../logo.svg";
import { navigate } from "@reach/router";

const NavigationBar = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          <img
            style={{ width: 50, height: 50, marginRight: 10 }}
            src={logo}
            alt="Smart Saver Logo"
          />
          <b>Smart Saver Affiliates</b>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="#" onClick={() => navigate("/profile")}>
              My Profile
            </Nav.Link>
            <Nav.Link href="#" onClick={() => navigate("/auth")}>
              Log out
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default NavigationBar;
