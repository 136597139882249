import { Link, RouteComponentProps } from "@reach/router";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Helmet from "react-helmet";
import SizedBox from "../../components/SizedBox";
import Table from "react-bootstrap/Table";
import "../../styles/app/all-sales.css";
import { useQuery } from "@apollo/client";
import { GetAffiliateSales } from "../../utils/graphql/queries";
import Spinner from "react-bootstrap/Spinner";
import Badge from "react-bootstrap/Badge";
import moment from "moment";

const AllSales = (props: RouteComponentProps) => {
  const { loading, error, data } = useQuery(GetAffiliateSales);
  if (loading)
    return (
      <div>
        <Container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "4rem",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Container>
      </div>
    );

  if (error)
    return (
      <Container>
        <SizedBox height={20} />
        <p>Unable to load data</p>
      </Container>
    );

  return (
    <>
      <Helmet>
        <title>All Sales</title>
      </Helmet>
      <Container fluid style={{ backgroundColor: "#fdc21a" }}>
        <Container style={{ paddingTop: "4rem", paddingBottom: "4rem" }}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Sales from date:</Form.Label>
                <Form.Control type="date" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Sales to date:</Form.Label>
                <Form.Control type="date" />
              </Form.Group>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ButtonToolbar>
                <Button variant="light">Search</Button>
                <SizedBox width={10} />
                <Button variant="danger">Clear</Button>
              </ButtonToolbar>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <Card className="stat-card">
                <Card.Body>
                  <Card.Title className="card-title">0</Card.Title>
                  <Card.Text>Results</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="stat-card">
                <Card.Body>
                  <Card.Title className="card-title">0</Card.Title>
                  <Card.Text>In sales</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="stat-card">
                <Card.Body>
                  <Card.Title className="card-title">0</Card.Title>
                  <Card.Text>Incomplete Sales</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <SizedBox height={40} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5>Your filter results</h5>
          <Button>Download CSV</Button>
        </div>
        <SizedBox height={30} />
        <Table>
          <thead>
            <tr>
              <th>Token</th>
              <th>Price</th>
              <th>Commision</th>
              <th>Code</th>
              <th>Status</th>
              <th>Source</th>
              <th>Date Honored</th>
              <th>Created</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {data.affiliatePayments.map((payment: any) => {
              return (
                <tr>
                  <td>
                    <Button variant="link" onClick={() => alert(payment.token)}>
                      Click to view
                    </Button>
                  </td>
                  <td>{payment.price}</td>
                  <td>{payment.commission}</td>
                  <td>
                    <Link to={`/code/${payment.discount_code.code}`}>
                      {payment.discount_code.code}
                    </Link>
                  </td>
                  <td>
                    <Badge
                      variant={payment.status === "new" ? "warn" : "success"}
                    >
                      {payment.status === "new" ? "Incomplete" : "Compelete"}
                    </Badge>
                  </td>
                  <td>{payment.source}</td>
                  <td>{moment(payment.date_honoured).format("LL")}</td>
                  <td>{payment.description}</td>
                  <td>{moment(payment.created_at).format("LL")}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default AllSales;
