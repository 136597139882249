import { Link, RouteComponentProps, navigate } from "@reach/router";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { Helmet } from "react-helmet";
import { FaList } from "react-icons/fa";
import SizedBox from "../../components/SizedBox";
import "../../styles/app/home.css";
import { useQuery } from "@apollo/client";
import { HomeQuery } from "../../utils/graphql/queries";

const Home = (_: RouteComponentProps) => {
  const { loading, error, data } = useQuery(HomeQuery);

  if (loading)
    return (
      <Container
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "4rem",
        }}
      >
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Container>
    );

  if (error)
    return (
      <Container>
        <p>Unable to load data</p>
      </Container>
    );

  const {
    completeSalesToday,
    incompleteSalesToday,
    allCompleteSales,
    allIncompleteSales,
    myInfo,
    myCoupons,
  } = data;
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Container fluid className="hero-banner">
        <Container style={{ paddingTop: "4rem", paddingBottom: "2rem" }}>
          <h1>Hi, {myInfo.name}</h1>
          <p>
            If you have any discount codes, they will appear below. Tap 'Edit'
            on one to view your sales.
          </p>
        </Container>
        <Container style={{ paddingTop: "2rem", paddingBottom: "4rem" }}>
          <h4>Your overview</h4>
          <Row>
            <Col sm={12} md={6} lg={3}>
              <Card className="stat-card">
                <Card.Body>
                  <Card.Title className="card-title">
                    {completeSalesToday}
                  </Card.Title>
                  <Card.Text>Complete Sales Today</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <Card className="stat-card">
                <Card.Body>
                  <Card.Title className="card-title">
                    {incompleteSalesToday}
                  </Card.Title>
                  <Card.Text>Incomplete Sales Today</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <Card className="stat-card">
                <Card.Body>
                  <Card.Title className="card-title">
                    {allCompleteSales}
                  </Card.Title>
                  <Card.Text>All Complete Sales</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <Card className="stat-card">
                <Card.Body>
                  <Card.Title className="card-title">
                    {allIncompleteSales}
                  </Card.Title>
                  <Card.Text>All Incomplete Sales</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
      <SizedBox height={40} />
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5>Your Discount Codes</h5>
          <ButtonToolbar>
            <Button onClick={() => navigate("/all-sales")} variant="light">
              <i style={{ marginRight: 10 }}>
                <FaList />
              </i>
              View all sales
            </Button>
            <SizedBox height={10} />
          </ButtonToolbar>
        </div>
        <hr />
        <SizedBox height={15} />
        {/* Here you're going to load the discount codes that are on the system for the user */}
        <Table striped bordered>
          <thead>
            <tr>
              <th>Name</th>
              <th>Code</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {myCoupons.map((coupon: any) => {
              return (
                <tr>
                  <td>{coupon.name}</td>
                  <td>{coupon.code}</td>
                  <td>
                    <Badge variant={coupon.active ? "success" : "danger"}>
                      {coupon.active ? "Active" : "Inactive"}
                    </Badge>
                  </td>
                  <td>
                    <Link to={`/code/${coupon.code}`}>Edit</Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default Home;
